import { PRODUCTS, PRODUCTS_WORLD } from '@/constants/product/productConstants';
import { formatRegionCode } from '@/utils/regionUtils';

const sanitizeNextProductQuery = ({ regionCode, nextProductQuery, lang }) => {
  const regionCodeLowerCase = regionCode.toLowerCase();
  const productsConfig =
    regionCodeLowerCase === 'ru' ||
    regionCodeLowerCase.includes('ru-') ||
    (regionCodeLowerCase === 'custom' && lang === 'ru')
      ? PRODUCTS
      : PRODUCTS_WORLD;
  return productsConfig.map(({ to }) => to.params.productId).includes(nextProductQuery) ? nextProductQuery : 'base';
};

const getProductRoute = ({ regionCode, productName, queryParams = {} }) => {
  if (['base', 'dem', 'msbld', 'msrd'].includes(productName))
    return {
      name: productName,
      params: {
        regionCode,
      },
      query: queryParams,
    };
  if (['gkh', 'sat', 'oopt', 'rnlic', 'overture'].includes(productName))
    return {
      name: 'simpleDataProduct',
      params: {
        regionCode,
        productId: productName,
      },
      query: queryParams,
    };
  return {
    name: 'base',
    params: {
      regionCode,
    },
    query: queryParams,
  };
};

const getNextProductRoute = ({ regionCode, route, query = {} }) => {
  const { 'next-product': nextProductQuery } = route.query || {};

  const nextProductName = sanitizeNextProductQuery({
    regionCode,
    nextProductQuery,
    lang: route.params.lang,
  });

  return getProductRoute({
    regionCode: formatRegionCode(regionCode),
    productName: nextProductName,
    queryParams: { ...query },
  });
};

const getProductNameFromRoute = (route) => {
  let productName = ['base', 'dem', 'msbld', 'msrd', 'simpleDataProduct'].includes(route.name) ? route.name : 'base';
  if (productName === 'simpleDataProduct') productName = route.params.productId;
  return productName;
};

export { getProductRoute, getProductNameFromRoute, sanitizeNextProductQuery, getNextProductRoute };
