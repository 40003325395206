import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { getFullFormatList } from '@/utils/formatUtils';
import { getProductImages } from '@/utils/productUtils';
import { LAST_UPDATED_TODAY, COMMON_VECTOR_FORMATS, DEFAULT_VECTOR_FORMAT } from '@/constants/product/productConstants';

const RNLIC_IMAGES = [
  { name: 'rnlic', format: 'png' },
  { name: 'rnlicAttrs', format: 'png' },
];

const getSettings = () =>
  Object.freeze({
    id: 'rnlic',
    description: i18n.t('product.rnlic.description'),
    lastUpdated: LAST_UPDATED_TODAY,
    productionTime: 5, // in minutes
    sources: [
      {
        text: i18n.t('product.rnlic.rosgeolfond'),
        link: 'https://rfgf.ru/info-resursy/otkrytye-dannye',
      },
    ],
    tags: i18n.t('product.rnlic.tags'),
    samplesUrl: `/ru/about/#formats-rnlic`,
    dataStructureUrl: {
      ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTDwgzllu93JNaL60BYHyTrirCqUigFwKYatMosvABIr8rVVHfmvHAaAy0D2gXFOw1Qcl2A2RN4O374/pubhtml',
      en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTDwgzllu93JNaL60BYHyTrirCqUigFwKYatMosvABIr8rVVHfmvHAaAy0D2gXFOw1Qcl2A2RN4O374/pubhtml',
    },
    demoMapUrl: {
      ru: 'https://demo.nextgis.ru/resource/6246/display',
      en: 'https://demo.nextgis.ru/resource/6246/display',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/rnlic_demo_map_bg.png`,
    productOptions: {
      formFields: {
        format: {
          name: 'format',
          label: `${i18n.t('product.format')}:`,
          widget: 'select',
          options: getFullFormatList(COMMON_VECTOR_FORMATS),
          serverErrors: '',
          errors: [],
        },
      },
    },
    productModel: {
      formModel: {
        format: DEFAULT_VECTOR_FORMAT,
      },
    },
    layerList: {
      attrs: {
        disabled: true,
      },
    },
    images: getProductImages(RNLIC_IMAGES, 'rnlic'),
  });

class RnlicProductService extends BaseProductService {
  getSettings() {
    return getSettings();
  }
}

export { RnlicProductService, getSettings, RNLIC_IMAGES };
